import { GET_ALLACTIVE_USER_FAILURE, GET_ALLACTIVE_USER_REQUEST, GET_ALLACTIVE_USER_SUCCESS } from "./allActiveuser.actionType";

const initState = {
    allActiveuser : [],
    allActiveUserLoading : false,
    allActiveUserError : false

}

export const all_active_user_reducer = (state=initState,{type,payload})=>{

    switch (type){
        case GET_ALLACTIVE_USER_REQUEST :
            return {
                ...state,
                allActiveUserLoading : true,
                allActiveUserError : false
            }
        case GET_ALLACTIVE_USER_SUCCESS : 
            return {
                ...state,
                allActiveUserLoading : false,
                allActiveUserError : false,
                allActiveuser : payload
            }
        case GET_ALLACTIVE_USER_FAILURE :
            return {
                ...state,
                allActiveUserLoading : false,
                allActiveUserError : true
            }
            default :
            return state
    }
}