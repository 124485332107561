import {combineReducers} from "redux";
import { Auth_reducer } from "./Auth/Auth.reducer";
import {adminprofile_reducer} from "./Getprofile/adminprofile.reducer"
import { alluser_reducer } from "./GetAllUsers/alluser.reducer";
import { all_active_user_reducer } from "./ActiveUsers/allActiveuser.reducer";
import { all_Inactive_user_reducer } from "./InActiveUsers/allInActiveuser.reducer";

export const rootReducer = combineReducers({    
    auth : Auth_reducer ,
    profile:adminprofile_reducer,
    alluser:alluser_reducer,
    allActiveUser:all_active_user_reducer,
    allInActiveUser:all_Inactive_user_reducer,

})