import React from 'react';

import {
    Box,
    LinearProgress
} from "@mui/material";

import logo from '../../assets/Images/brandLogo.png';

const LoadingScreen = () => {
    return (
        <>
            <Box sx={{ width: '100vw', height: '100vh', bgcolor: "#fff" }}>
                <Box sx={{
                    width: '50%', height: '15rem',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <img src={logo} width={250} alt="logo" />
                    <Box mt={3} sx={{ width: '100%' }} >
                        <LinearProgress color='error' />
                    </Box>
                </Box>
            </Box>

        </>
    )
}

export default LoadingScreen;