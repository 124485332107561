import {  GET_ALLUSER_FAILURE, GET_ALLUSER_REQUEST, GET_ALLUSER_SUCCESS } from "./alluser.actionType"


export const get_alluser_request = ()=>{
    return{
        type :GET_ALLUSER_REQUEST
    }
}

export const get_alluser_success = (payload)=>{
    return{
        type : GET_ALLUSER_SUCCESS,
        payload : payload
    }
}
export const get_alluser_failure = ()=>{
    return{
        type : GET_ALLUSER_FAILURE
    }
}
