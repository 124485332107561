import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authAxios from '../../Utils/axios';

// Material Components
import {
    Container,
    Stack,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    CssBaseline,
} from '@mui/material';

import LoadingScreen from '../../Components/LoadingScreen/LoadingScreen';

// Image
import loginImg from "../../assets/Images/loginImg.png";

// Icons
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

// Validation
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';

// Styled Components
import { createGlobalStyle } from 'styled-components';

// Material Styles
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from "react-redux";
import { loginApi } from '../../Redux/Auth/loginApi';
import { getAdminProfile } from '../../Redux/Getprofile/adminprofileAPI';
import { api_route } from '../../Api-Config/config';
import axios from 'axios';


import useAuth from '../../hooks/useAuth';


const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgba(229, 50, 103, 0.1) ;
  }
`;

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const Login = () => {
    // Redux Function
    const dispatch = useDispatch();
    const { isLogin, isLoading,isError } = useSelector((state) => state.auth);
    const { admin, isAdminLoading,isAdminError } = useSelector((state) => state.profile);


    // Use Context 

    const { login } = useAuth();

    

    const navigate = useNavigate();





    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    
    // const getAdminProfile = async() => {
    //     const token = window.localStorage.getItem("token");
    // console.log(token,"token in login")
    //     try{
    //         setLoading(true);
    //         // dispatch(getAdminProfile(token))
    //         const response = await axios.get(`${api_route}/getProfile`, {
    //             headers: {
    //                 "token": token,
    //             },
    //         });
    //         console.log(response,"profile response")
    //         if(response.data.success){

    //             navigate("/admin/allUsers");
    //         }
    //         setLoading(false);

    //     }
    //     catch(err){
    //         setLoading(false);

    //         console.log(err);
    //     }
    // }
  
    // useEffect(()=>{
    //     if(isLogin){

    //         getAdminProfile()
    //     }
    // },[])
    useEffect(() => {
        setLoading(true);
     
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, []);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        // confirmPassword: Yup.string().required('Confirm Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, { setErrors, resetForm }) => {
            try {
                const   payload = {
                    email: values.email,
                    password: values.password,
                  };
                setLoading(true);
                const res = await login(payload);
                console.log(res,"login velues")
                // dispatch(loginApi(values.email,values.password))
                if (res.success) {
                    navigate("/admin/allUsers");
                    // resetForm();
                  } else {
                    setErrors({ afterSubmit: res.error });
                  }
                setLoading(false);
                // resetForm();
            } catch (error) {
                setErrors({ afterSubmit: error.message });
                resetForm();
            }
        }
    });

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    };

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    const color = "#e53267";

    return (
        <>
            {
                isLoading && <LoadingScreen />
            }
            <ThemeProvider theme={lightTheme}>
                <GlobalStyle />
                <CssBaseline />
                <Container maxWidth="lg"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: "50px"
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ margin: "auto", display: "flex", justifyContent: "center" }}>
                            <img src={loginImg} width={400} alt="login" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ margin: "auto" }}>
                            <Typography mb={3} variant="h4">Welcome <span style={{ color: "#e53267" }}>back!</span></Typography>

                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={2} mb={3} direction="column">
                                        <Stack sx={{ width: '100%' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                sx={{ '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: color } } }}
                                                placeholder="Email"
                                                type='email'
                                                {...getFieldProps('email')}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Stack>
                                        <Stack sx={{ width: '100%' }}>
                                            <TextField
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder='Password'
                                                required
                                                sx={{ '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: color } } }}
                                                {...getFieldProps('password')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton onClick={handleShowPassword} edge="end">
                                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                            />
                                        </Stack>
                                        {/* <Stack sx={{ width: '100%' }}>
                                            <TextField
                                                fullWidth
                                                required
                                                placeholder="Confirm Password"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                sx={{ '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: color } } }}
                                                {...getFieldProps('confirmPassword')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconButton onClick={handleShowConfirmPassword} edge="end">
                                                                <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                helperText={touched.confirmPassword && errors.confirmPassword}
                                            />
                                        </Stack> */}
                                    </Stack>
                                    <Button
                                        fullWidth
                                        type='submit'
                                        variant='contained'
                                        sx={{
                                            mb: 3,
                                            backgroundColor: color, color: 'white',
                                            '&:hover': {
                                                backgroundColor: color,
                                                color: 'white',
                                            }
                                        }}
                                        disabled={errors.email || errors.password || errors.confirmPassword ? true : false}
                                    >
                                        Login
                                    </Button>
                                </Form>
                            </FormikProvider>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        </>
    )
}

export default Login;