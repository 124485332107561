import {  GET_ADMINPROFILE_FAILURE, GET_ADMINPROFILE_REQUEST, GET_ADMINPROFILE_SUCCESS } from "./adminprofile.actionType"

const initState = {
    admin : {},
    isAdminLoading : false,
    isAdminError : false

}

export const adminprofile_reducer = (state=initState,{type,payload})=>{

    switch (type){
        case GET_ADMINPROFILE_REQUEST :
            return {
                ...state,
                isAdminLoading : true,
                isAdminError : false
            }
        case GET_ADMINPROFILE_SUCCESS : 
            return {
                ...state,
                isAdminLoading : false,
                isAdminError : false,
                admin : payload
            }
        case GET_ADMINPROFILE_FAILURE :
            return {
                ...state,
                isAdminLoading : false,
                isAdminError : true
            }
            default :
            return state
    }
}