import { createContext, useEffect, useState } from "react";

import authAxios from "../Utils/axios";
import { isValidToken, setSession } from "../Utils/jwt";
import { api_route } from "../Api-Config/config";
import LoadingScreen from "../Components/LoadingScreen/LoadingScreen";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  let type;

  const initialize = async () => {
    // let id;
    console.log("get profile function call")
    
    try {  
      setLoading(true);
      const token = window.localStorage.getItem("token");
      // if (token && isValidToken(token)) {
        console.log('access token', token);
        setSession(token);
       
        var response = await authAxios.get(`${api_route}/getProfile`);

        const userData = response.data.data;
        console.log(userData, "admin profile");
        setUser(userData);
        // id = userData._id;
        setLoading(false);
      // } else {
        // setUser(null);
        // setLoading(false);
      // }
    } catch (error) {
      console.log(error);
      setUser(null);
      setLoading(false);
    }
  };
console.log(user,"in context")
  useEffect(() => {
    initialize();
  }, []);

  const login = async ({ email, password }) => {
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    // if()
    const response = await authAxios.post(`${api_route}/login`, {
      email,
      password,
    });

    const data = response.data;
    console.log(data,"in login value");
    if (data.success) {
      console.log("Super admin log in ");
      // const {token, type} = data.data;
      const token = data.data.token;
      type = data.data.type;
      localStorage.setItem("type", type);
      setSession(token);
      await initialize();
      return { success: true, error: "" };
    } 
       else {
        return { success: false, error: data.error };
      }
    
  };

  const logout = async () => {
    setSession(null);
    setUser(null);
  };

  const values = {
    user,
    login,
    logout,
  };
  return (
    <AuthContext.Provider value={values}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
