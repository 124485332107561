import { useState, useEffect } from 'react';
import './App.css';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';


import Router from "./Router";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });

    };
    clearCacheData();
  }, [])


  return (
    <div>
      {/* {
        !loading ? (
          <LoadingScreen />
        ) : (
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/admin" element={<DashboardLayout />}>
                <Route path="allUsers" element={<AllUsers />} />
                <Route path="activeUsers" element={<ActiveUsers />} />
                <Route path="inactiveUsers" element={<InactiveUsers />} />
              </Route>
            </Routes>
          </Router>
        )
      } */}

     { !loading ? (
          <LoadingScreen />
        ) : (
          <Router/>
        )
     }
    </div>
  );
}

export default App;
