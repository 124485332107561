import React, { useEffect, useState } from "react";

// Material Components
import {
  Box,
  // Grid,
  // Card,
  // CardHeader,
  // CardActions,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Avatar,
  Button,
  IconButton,
  Tooltip,
  Modal,
  Stack,
  TextField,
  Alert,
  ImageList,
  ImageListItem,
} from "@mui/material";
import * as Yup from "yup"
// Material Table
import MaterialTable from "@material-table/core";

// Image
import defaultImage from "../../assets/Images/defaultAvatar.png";

// Icon
import InfoIcon from "@mui/icons-material/Info";
import MoreInfo from "../MoreInfo/MoreInfo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../Redux/GetAllUsers/alluserAPI";
import { api_route } from '../../Api-Config/config';
import authAxios from '../../Utils/axios';
import { hover } from "@testing-library/user-event/dist/hover";

import CommanMaterialTable from "../CommanUserTable/MaterialTable";



const Dashboard = () => {
 
  const { alluser, allUserLoading, allUserError } = useSelector((state) => state.alluser);
  // console.log(alluser)
  // // const [rowData, setRowData] = useState(null);
  // const [message, setMessage] = useState()
  // const [viewDoc, setViewDoc] = useState(false)
  // const [open, setOpen] = useState(false);
  // const [transcationStatus, setTranscation] = useState()
  // const [id, setId] = useState()
  // const [modData, setModData] = useState(null);
  // const [request, setRequest] = useState(false);
  // const [status, setStatus] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const handleRequest = () => {
  //   setRequest((prev) => !prev);
  // };

  // const handleStatus = async ({ _id }) => {
  //   // setStatus((prev) => !prev);
  //   console.log(_id)

  //   await authAxios.put(`${api_route}/toggleemailauth`, {
  //     id: _id
  //   },).then(res => {
  //     console.log(res)
  //     dispatch(getAllUsers());
  //   }).catch(error => {
  //     console.log(error.message)
  //   })


  // };
  // const handlePaymentReceived = async (id) => {
  //   setTranscation(false)
  //   console.log(id)
  //   await authAxios.post(`${api_route}/togglePaymentStatus`, {
  //     id
  //   },).then(res => {
  //     console.log(res)
  //     setMessage(res.data.message)
  //     setTimeout(() => {
  //       setMessage()
  //     }, 4000)
  //     dispatch(getAllUsers());
  //   }).catch(error => {
  //     console.log(error)
  //     alert(error.data.message)
  //   })

  // }

  // useEffect(() => {
  //   dispatch(getAllUsers());
  // }, [])
  // const columns = [
  //   // { title: "ID", field: "id", width: "15rem" },
  //   {
  //     title: "Profile", width: "10rem",
  //     render: (rowData) => (
  //       // <img src={rowData.profile} style={{ width: 50, borderRadius: '50%' }} />
  //       <Avatar
  //         sizes="large"
  //         sx={{
  //           border: `2px solid ${color}`,
  //           width: 45,
  //           height: 45,
  //         }}
  //         alt="match"
  //         src={rowData.profilePicture ? rowData.profilePicture : defaultImage}
  //       ></Avatar>
  //     )
  //   },
  //   { title: "Name", field: "fullname", width: "13rem", cellStyle: { overflowWrap: "break-word" } },
  //   {
  //     title: "Request", width: "10rem",
  //     render: (rowData) => (
  //       <Button
  //         size="small"
  //         variant="contained"
  //         onClick={handleRequest}
  //         sx={{
  //           backgroundColor: request ? "grey" : color,
  //           color: "white",
  //           "&:hover": {
  //             backgroundColor: request ? "grey" : color,
  //             color: "white",
  //           },
  //         }}
  //       >
  //         {request ? "Deny" : "Allow"}
  //       </Button>
  //     )
  //   },
  //   {
  //     title: "Status", width: "10rem",
  //     render: (rowData) => (
  //       <FormGroup>
  //         <FormControlLabel control={
  //           <Switch
  //             checked={rowData.emailAuth}
  //             onChange={() => handleStatus(rowData)}
  //             // onClick={handleClickStatus(rowData)}
  //             sx={{
  //               '& .MuiSwitch-switchBase.Mui-checked': {
  //                 color: color,
  //                 '&:hover': {
  //                   backgroundColor: 'rgba(229, 50, 103, 0.04)',
  //                 },
  //               },
  //               '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
  //                 backgroundColor: color,
  //               },
  //             }}
  //           />
  //         }
  //           label={rowData.emailAuth ? "Active" : "Inactive"} />
  //       </FormGroup>
  //     )
  //   },
  //   {
  //     title: "More Info", width: "10rem",
  //     render: (rowData) => (
  //       <Tooltip title="More Info" placement="left">
  //         <IconButton
  //           size="large"
  //           onClick={() => {
  //             setModData(rowData);
  //             handleOpen();
  //           }}
  //         >
  //           <InfoIcon sx={{ color: color }} />
  //         </IconButton>
  //       </Tooltip>
  //     )
  //   },
  //   {
  //     title: "Payment Received", field: "paymentReceived", width: "12rem",
  //     render: rowData => (
  //       <FormGroup>
  //         <FormControlLabel control={
  //           <Switch
  //             checked={rowData.paymentStatus}
  //             onChange={() => {
  //               setId(rowData._id)
  //               rowData.transactionID ? handlePaymentReceived(rowData._id) : setTranscation(true)
  //             }}
  //             // onClick={handleClickStatus(rowData)}
  //             sx={{
  //               '& .MuiSwitch-switchBase.Mui-checked': {
  //                 color: color,
  //                 '&:hover': {
  //                   backgroundColor: 'rgba(229, 50, 103, 0.04)',
  //                 },
  //               },
  //               '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
  //                 backgroundColor: color,
  //               },
  //             }}
  //           />
  //         }
  //           label={rowData.paymentStatus ? "Yes" : "No"} />
  //       </FormGroup>
  //     )
  //   },
  //   { title: "Transaction Id", field: "transactionID", width: "13rem" },
  //   {
  //     title: "View Docs", field: "viewDocs", width: "10rem",
  //     render: (rowData) => (
  //       <Button
  //         size="small"
  //         variant="contained"
  //         onClick={() => {
  //           setModData(rowData)
  //           setViewDoc(true)

  //         }}
  //         sx={{
  //           backgroundColor: color,
  //           color: "white",
  //           "&:hover": {
  //             backgroundColor: color,
  //             color: "white",
  //           },
  //         }}
  //       >
  //         View
  //       </Button>
  //     )
  //   }
  // ];

  // const allUserData = [
  //   {
  //     id: 1,
  //     profile: defaultImage,
  //     name: "John Doe",
  //     request: "Allow",
  //   },
  // ];

  return (
    <>
    <CommanMaterialTable getUsers={getAllUsers} usersData={alluser} title="All Users"/>
      {/* <CommanMaterialTable /> */}
      {/* <Box mt={10}>
        <Grid container spacing={2}>
          {data.map((item) => (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card key={item.id} sx={{ bgcolor: "whitesmoke" }}>
                <CardHeader
                  avatar={
                    <Avatar
                      sizes="large"
                      sx={{
                        border: `2px solid ${color}`,
                        width: 100,
                        height: 100,
                      }}
                      alt="match"
                      src={defaultImage}
                    ></Avatar>
                  }
                  title={item.name}
                  subheader={item.dob}
                />
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <div>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: color,
                          color: "white",
                          "&:hover": {
                            backgroundColor: color,
                            color: "white",
                          },
                        }}
                      >
                        Allow
                      </Button>
                    </div>

                    <div>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: "grey",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "grey",
                            color: "white",
                          },
                        }}
                      >
                        Deny
                      </Button>
                    </div>
                  </Box>
                  <div>
                    <Tooltip title="More Info" placement="left">
                      <IconButton
                        onClick={() => {
                          setModData(item);
                          handleOpen();
                        }}
                      >
                        <InfoIcon sx={{ color: color }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box> */}

      {/* <Box mt={10} xs={12} sm={12} md={12} lg={12}>



        <MaterialTable
          title={<Typography color={color} variant='h5'>All Users</Typography>}
          columns={columns}
          data={alluser}
          options={{
            actionsColumnIndex: -1,
            addRowPosition: "first",
            tableLayout: "fixed",
            headerStyle: {
              backgroundColor: color,
              color: '#FFF',
              fontWeight: "bold", fontSize: "1rem",
            }
          }}
        />
      </Box>

      {/* More Info Modal */}
      {/* <Modal open={open} onClose={handleClose}>
        <MoreInfo handleClose={handleClose} data={modData} />
      </Modal> */}
      {/* Transaction Modal */}
      {/* <Modal open={transcationStatus}>
        <TransactionAdd
          handlePaymentReceived={handlePaymentReceived}
          setTranscation={setTranscation}
          setId={setId}
          getUsers={getAllUsers}
          id={id}
        />
      </Modal> */}
      {/* Alert Modal */}
      {/* <Modal open={message}>
        <AlertBox message={message} setMessage={setMessage} />
      </Modal> */}
      {/* View Document Modal */}
      {/* <Modal open={viewDoc}>
        <ViewDoc setViewDoc={setViewDoc} modData={modData} />
      </Modal> */} 


    </>
  );
};

export default Dashboard;
