import {  GET_ALLACTIVE_USER_FAILURE, GET_ALLACTIVE_USER_REQUEST, GET_ALLACTIVE_USER_SUCCESS } from "./allActiveuser.actionType"


export const get_allactive_user_request = ()=>{
    return{
        type :GET_ALLACTIVE_USER_REQUEST
    }
}

export const get_allactive_user_success = (payload)=>{
    return{
        type : GET_ALLACTIVE_USER_SUCCESS,
        payload : payload
    }
}
export const get_allactive_user_failure = ()=>{
    return{
        type : GET_ALLACTIVE_USER_FAILURE
    }
}
