import {  GET_ALL_INACTIVE_USER_FAILURE, GET_ALL_INACTIVE_USER_REQUEST, GET_ALL_INACTIVE_USER_SUCCESS } from "./allInActiveuser.actionType"

const initState = {
    allInActiveuser : [],
    allInActiveUserLoading : false,
    allInActiveUserError : false

}

export const all_Inactive_user_reducer = (state=initState,{type,payload})=>{

    switch (type){
        case GET_ALL_INACTIVE_USER_REQUEST :
            return {
                ...state,
                allInActiveUserLoading : true,
                allInActiveUserError : false
            }
        case GET_ALL_INACTIVE_USER_SUCCESS : 
            return {
                ...state,
                allInActiveUserLoading : false,
                allInActiveUserError : false,
                allInActiveuser : payload
            }
        case GET_ALL_INACTIVE_USER_FAILURE :
            return {
                ...state,
                allInActiveUserLoading : false,
                allInActiveUserError : true
            }
            default :
            return state
    }
}