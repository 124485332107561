import React, { useEffect, useState } from "react";
import "./gallery.css"
// Material Components
import {
    Box,
    // Grid,
    // Card,
    // CardHeader,
    // CardActions,
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
    Avatar,
    Button,
    IconButton,
    Tooltip,
    Modal,
    Stack,
    TextField,
    Alert,
    ImageList,
    ImageListItem,
    Autocomplete,
} from "@mui/material";
import * as Yup from "yup"
// Material Table
import MaterialTable from "@material-table/core";

// Image
import defaultImage from "../../assets/Images/defaultAvatar.png";

// Icon
import InfoIcon from "@mui/icons-material/Info";
import MoreInfo from "../MoreInfo/MoreInfo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../Redux/GetAllUsers/alluserAPI";
import { api_route } from '../../Api-Config/config';
import authAxios from '../../Utils/axios';
import { hover } from "@testing-library/user-event/dist/hover";
import { Form, FormikProvider, useFormik } from "formik";
import { render } from "@testing-library/react";
import NoDataImage from "../../assets/Images/NoDataFound.png"


const CommanMaterialTable = ({ getUsers, usersData, title }) => {
    const transactionSchema = Yup.object().shape({
        transactionID: Yup.string().required("Transaction ID is required")
    })
    const formik = useFormik({
        initialValues: {
            transactionID: ""
        },
        validationSchema: transactionSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log(values, id)
            await authAxios.post(`${api_route}/addTransactionId`, {
                id, transactionID: values.transactionID
            },).then(res => {
                console.log(res)
                resetForm()
                // setTranscation(res.data.data.transactionID)
                handlePaymentReceived(id)
                setId()
                setTranscation(false)
                dispatch(getUsers());
            }).catch(error => {
                console.log(error)
                handlePaymentReceived(id)
                setId()
            })
        }
    })
    const { errors, touched, handleSubmit, getFieldProps } = formik;
    const color = "#e53267";
    const dispatch = useDispatch();
    const { alluser, allUserLoading, allUserError } = useSelector((state) => state.alluser);
    console.log(alluser)
    // const [rowData, setRowData] = useState(null);
    const [message, setMessage] = useState()
    const [viewDoc, setViewDoc] = useState(false)
    const [open, setOpen] = useState(false);

    const [transcationStatus, setTranscation] = useState()
    const [id, setId] = useState()
    const [modData, setModData] = useState(null);
    const [request, setRequest] = useState(false);
    const [status, setStatus] = useState(false);
    const [dormant,setDormant] = useState(false);
    const [reason,setReason] = useState("")

    const reasonForINactive = [
        "Married","Engaged","Not interested Anymore","Other"
       ]


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRequest = async(rowData) => {
        // setRequest((prev) => !prev);
        const {email} = rowData;
        console.log(email.toLowerCase());
        try{
           let res =  await authAxios.post(`${api_route}/sentPaymentRemainder`, {
                email: email.toLowerCase()
            },)
            console.log(res)
            alert(res.data.message)
        }
        catch(err){
            console.log(err.message);
            alert(err.message)
        }
    };

    const handleStatus = async ({ _id }) => {
        // setStatus((prev) => !prev);
        console.log(_id)

        await authAxios.put(`${api_route}/toggleemailauth`, {
            id: _id
        },).then(res => {
            console.log(res.data)
            setStatus(res.data)
            dispatch(getUsers());
            setTimeout(()=>{
                setStatus()
            },2000)
        }).catch(error => {
            console.log(error.message)
        })


    };
    const handleChangeDormantStatus = async (dormant) => {
        // setStatus((prev) => !prev);
        console.log(dormant)
        const payload = {
            "id":dormant._id,
            "reasonFordormant":reason
        }
        await authAxios.put(`${api_route}/dormantAccount`,payload,).then(res => {
            console.log(res.data)
            setDormant(res.data)
            dispatch(getUsers());
            setTimeout(()=>{
                setDormant()
            },2000)
        }).catch(error => {
            console.log(error.message)
        })


    };
    const handlePaymentReceived = async (id) => {
        setTranscation(false)
        console.log(id)
        await authAxios.post(`${api_route}/togglePaymentStatus`, {
            id
        },).then(res => {
            console.log(res)
            setMessage(res.data.message)
            setTimeout(() => {
                setMessage()
            }, 4000)
            dispatch(getUsers());
        }).catch(error => {
            console.log(error)
            alert(error.data.message)
        })

    }

    useEffect(() => {
        dispatch(getUsers());
    }, [])
    const columns = [
        // { title: "ID", field: "id", width: "15rem" },
        {
            title: "Profile", width: "10rem",
            render: (rowData) => (
                // <img src={rowData.profile} style={{ width: 50, borderRadius: '50%' }} />
                <Avatar
                    sizes="large"
                    sx={{
                        border: `2px solid ${color}`,
                        width: 45,
                        height: 45,
                    }}
                    alt="match"
                    src={rowData.profilePicture ? rowData.profilePicture : defaultImage}
                ></Avatar>
            )
        },
        {
            title: "Name", field: "fullname", width: "13rem", cellStyle: { overflowWrap: "break-word" },
            render: rowData => (
                rowData.fullname ? rowData.fullname : `${rowData.firstName} ${rowData.lastName}`
            )
        },
        {
            title: "Send Payment Link", width: "14rem",
            render: (rowData) => (
                <Button
                    size="small"
                    variant="contained"
                    onClick={()=>handleRequest(rowData)}
                    sx={{
                        backgroundColor: color,
                        color: "white",
                        "&:hover": {
                            backgroundColor: request ? "grey" : color,
                            color: "white",
                        },
                    }}
                >
                    {request ? "Deny" : "Send Link"}
                </Button>
            )
        },
        {
            title: "Status", width: "10rem",
            render: (rowData) => (
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={rowData.emailAuth}
                            onChange={() => setStatus(rowData)}
                            // onClick={handleClickStatus(rowData)}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: color,
                                    '&:hover': {
                                        backgroundColor: 'rgba(229, 50, 103, 0.04)',
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: color,
                                },
                            }}
                        />
                    }
                        label={rowData.emailAuth ? "Active" : "Inactive"} />
                </FormGroup>
            )
        },
        {
            title: "Dormant", width: "10rem",
            render: (rowData) => (
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={rowData.dormant}
                            onChange={() => setDormant(rowData)}
                            // onClick={handleClickStatus(rowData)}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: color,
                                    '&:hover': {
                                        backgroundColor: 'rgba(229, 50, 103, 0.04)',
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: color,
                                },
                            }}
                        />
                    }
                        label={rowData.dormant ? "Yes" : "No"} />
                </FormGroup>
            )
        },
        {
            title: "More Info", width: "10rem",
            render: (rowData) => (
                <Tooltip title="More Info" placement="left">
                    <IconButton
                        size="large"
                        onClick={() => {
                            setModData(rowData);
                            handleOpen();
                        }}
                    >
                        <InfoIcon sx={{ color: color }} />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            title: "Payment Received", field: "paymentReceived", width: "12rem",
            render: rowData => (
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={rowData.paymentStatus}
                            onChange={() => {
                                setId(rowData._id)
                                rowData.transactionID ? handlePaymentReceived(rowData._id) : setTranscation(true)
                            }}
                            // onClick={handleClickStatus(rowData)}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: color,
                                    '&:hover': {
                                        backgroundColor: 'rgba(229, 50, 103, 0.04)',
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: color,
                                },
                            }}
                        />
                    }
                        label={rowData.paymentStatus ? "Yes" : "No"} />
                </FormGroup>
            )
        },
        { title: "Transaction Id", field: "transactionID", width: "13rem" },
        {
            title: "View Docs", field: "viewDocs", width: "10rem",
            render: (rowData) => (
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                        setModData(rowData)
                        setViewDoc(true)

                    }}
                    sx={{
                        backgroundColor: color,
                        color: "white",
                        "&:hover": {
                            backgroundColor: color,
                            color: "white",
                        },
                    }}
                >
                    View
                </Button>
            )
        }
    ];

    const allUserData = [
        {
            id: 1,
            profile: defaultImage,
            name: "John Doe",
            request: "Allow",
        },
    ];

    return (
        <>
            {/* <CommanMaterialTable /> */}
            {/* <Box mt={10}>
        <Grid container spacing={2}>
          {data.map((item) => (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card key={item.id} sx={{ bgcolor: "whitesmoke" }}>
                <CardHeader
                  avatar={
                    <Avatar
                      sizes="large"
                      sx={{
                        border: `2px solid ${color}`,
                        width: 100,
                        height: 100,
                      }}
                      alt="match"
                      src={defaultImage}
                    ></Avatar>
                  }
                  title={item.name}
                  subheader={item.dob}
                />
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <div>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: color,
                          color: "white",
                          "&:hover": {
                            backgroundColor: color,
                            color: "white",
                          },
                        }}
                      >
                        Allow
                      </Button>
                    </div>

                    <div>
                      <Button
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: "grey",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "grey",
                            color: "white",
                          },
                        }}
                      >
                        Deny
                      </Button>
                    </div>
                  </Box>
                  <div>
                    <Tooltip title="More Info" placement="left">
                      <IconButton
                        onClick={() => {
                          setModData(item);
                          handleOpen();
                        }}
                      >
                        <InfoIcon sx={{ color: color }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box> */}

            <Box mt={10} xs={12} sm={12} md={12} lg={12}>



                <MaterialTable
                    title={<Typography color={color} variant='h5'>{title}</Typography>}
                    columns={columns}
                    data={usersData}
                    options={{
                        actionsColumnIndex: -1,
                        addRowPosition: "first",
                        tableLayout: "fixed",
                        headerStyle: {
                            backgroundColor: color,
                            color: '#FFF',
                            fontWeight: "bold", fontSize: "1rem",
                        }
                    }}
                />
            </Box>

            {/* More Info Modal */}
            <Modal open={open} onClose={handleClose}>
                <MoreInfo handleClose={handleClose} data={modData} />
            </Modal>
            <Modal open={transcationStatus}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "100%", sm: "60%", md: "40%", lg: "30%" },

                        overflowY: "auto",
                        scrollBehavior: "smooth",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Stack direction="row" justifyContent="flex-end">
                        <IconButton color="error" onClick={() => setTranscation(false)}>
                            <CancelIcon />

                        </IconButton>
                    </Stack>
                    <Box mt={2} sx={{ display: "flex", justifyContent: "center", alignContent: "center", alignContent: "center" }}>
                        <Stack>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <TextField id="outlined-basic" label="Transaction ID" variant="outlined"
                                        {...getFieldProps('transactionID')}
                                        error={Boolean(touched.transactionID && errors.transactionID)}
                                        helperText={touched.transactionID && errors.transactionID}

                                    />

                                    <Stack direction="row" width="100%" mt={3} spacing={3}>
                                        <Button variant="contained" color="error" onClick={() => setTranscation(false)}>Cancel</Button>
                                        <Button variant="contained" type='submit'>Confirm</Button>
                                    </Stack>
                                </Form>
                            </FormikProvider>


                        </Stack>

                    </Box>

                </Box>
            </Modal>
            <Modal open={message}>
                <Stack mb={2} sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 20,
                }} >
                    {message && <Alert severity="info" onClose={() => { setMessage() }}>{message}</Alert>}
                </Stack>
            </Modal>
            <Modal open={viewDoc}>

                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 20,
                    backgroundColor: "white",
                    p: 2

                }}

                >
                    <Stack direction="row" justifyContent="flex-end">

                        <IconButton color="error" onClick={() => setViewDoc(false)}>
                            <CancelIcon />
                        </IconButton>
                    </Stack>{modData?.gallery.length || modData?.resume ?
                        <Box>

                            {/* <ImageList variant="masonry" cols={3} gap={8}>
                                {modData?.gallery && modData?.gallery.map((image) => (
                                    <ImageListItem key={image}>
                                        <img
                                            src={image}
                                            srcSet={image}
                                            alt={image}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList> */}
                            <div className="gallery">
                                {modData?.gallery && modData?.gallery.map((image) => (
                                    <div key={image} className="image">
                                        <img
                                            src={image}
                                            srcSet={image}
                                            alt={image}
                                            loading="lazy"
                                            style={{
                                                width: "100%",
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <embed
                                src={modData?.resume}
                                type="application/pdf"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "none"
                                }}
                            />
                        </Box> :
                        <Box>
                            <img
                                src={NoDataImage}
                            />
                        </Box>
                    }


                </Box>



            </Modal>
            <Modal open={status}>
                <Stack mb={2} sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 20,
                    backgroundColor: "#ffff",
                    p:3,
                    paddingTop:1,
                    borderRadius:1
            
                }} >
                    <Stack direction="row" justifyContent="flex-end" pb={2}>

                        <IconButton color="error" onClick={() => setStatus()}>
                            <CancelIcon />
                        </IconButton>
                    </Stack>
                    <Stack>
                       {status?.message ?<Alert severity="success">Your status changed successfully!!</Alert>: <Stack><Typography mb={2}>Confirm to change the Status!</Typography>
                        <Button variant="contained" onClick={()=>handleStatus(status)}>Confirm</Button></Stack>}
                       
                    </Stack>

                </Stack>
            </Modal>

            <Modal open={dormant}>
                <Stack mb={2} sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: 20,
                    backgroundColor: "#ffff",
                    p:3,
                    paddingTop:1,
                    borderRadius:1
            
                }} >
                    <Stack direction="row" justifyContent="flex-end" pb={2}>

                        <IconButton color="error" onClick={() => setDormant()}>
                            <CancelIcon />
                        </IconButton>
                    </Stack>
                    
                    <Box>
                    <Autocomplete
                          size="small"
                          variant="outlined"
                          // disabled={!edit}
                          options={reasonForINactive}
                          value={reason}
                          onChange={(e, newValue) => {
                            setReason(newValue)
                            
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Reasone for Inactivate Account"
                              required
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: color,
                                  },
                                },
                                "& .MuiFormLabel-root.Mui-focused": {
                                  color: color,
                                },
                              }}
                            
                            />
                          )}
                        />
                    </Box>
                    <Stack>
                       {dormant?.message ?<Alert severity="success">Your status changed successfully!!</Alert>: <Stack><Typography mb={2}>Confirm to change the Status!</Typography>
                        <Button variant="contained" onClick={()=>handleChangeDormantStatus(dormant)}>Confirm</Button></Stack>}
                       
                    </Stack>

                </Stack>
            </Modal>

        </>
    );
};
export default CommanMaterialTable

