import jwtDecode from 'jwt-decode';

// Do not use it, it send issues 
// import {verify, sign} from 'jsonwebtoken';

import axios from './axios';

// ****************** check is token is valid ************************

const isValidToken = (token) => {
    if(!token){
        return false;
    }

    const decoded = jwtDecode(token);
    
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
    return true;
}

const setSession = (token) => {
    if(token){
        window.localStorage.setItem('token', token);
        axios.defaults.headers.common.token = `${token}`;
        console.log('token set');
    }else{
        window.localStorage.removeItem("token");
        delete axios.defaults.headers.common.token;
    
    }
}

// export {isValidToken, setSession, verify, sign};
export {isValidToken, setSession};