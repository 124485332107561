import {  GET_ALL_INACTIVE_USER_FAILURE, GET_ALL_INACTIVE_USER_REQUEST, GET_ALL_INACTIVE_USER_SUCCESS } from "./allInActiveuser.actionType"


export const get_all_inactive_user_request = ()=>{
    return{
        type :GET_ALL_INACTIVE_USER_REQUEST
    }
}

export const get_all_inactive_user_success = (payload)=>{
    return{
        type : GET_ALL_INACTIVE_USER_SUCCESS,
        payload : payload
    }
}
export const get_all_inactive_user_failure = ()=>{
    return{
        type : GET_ALL_INACTIVE_USER_FAILURE
    }
}
