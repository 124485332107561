import { Navigate, useRoutes } from "react-router-dom";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";


import { useState, useEffect } from 'react';

import {
  Routes,
  Route,
} from "react-router-dom";

// Components
import Login from '../Components/Login/Login';
import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import DashboardLayout from '../Components/Layouts/index';

// Pages
import AllUsers from '../Components/AllUsers/AllUsers';
import ActiveUsers from '../Components/ActiveUsers/ActiveUsers';
import InactiveUsers from '../Components/InactiveUsers/InactiveUsers';
import DormantUsers from "../Components/DormantUsers";



const Router = () => {
    return useRoutes([
        {
            path: "/",
            element: <Login />,
            children: [
                { path: "", element: <Navigate to="allUsers" /> },
                { path: "allUsers", element: <AllUsers /> },
                { path: "activeUsers", element: <ActiveUsers /> },
                { path: "inactiveUsers", element: <InactiveUsers /> },
                { path: "dormantUsers", element: <DormantUsers /> },
            ]
          },
        {
            path: "auth",
            children: [
              {
                path: "",
                element: <Navigate to="/auth/login" />,
              },
              {
                path: "login",
                element: (
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                ),
              },
            ],
          },
          {
            path: "login",
            element: <Navigate to="/auth/login" />,
          },
        {
            path: "admin",
            element: (
              <AuthGuard>
                <DashboardLayout />
              </AuthGuard>
            ),
            children: [
              { path: "", element: <Navigate to="/admin/allUsers" /> },

              { path: "allUsers", element: <AllUsers /> },
              { path: "activeUsers", element: <ActiveUsers /> },
              { path: "inactiveUsers", element: <InactiveUsers /> },
              { path: "dormantUsers", element: <DormantUsers /> },

            //   { path: "all-transactions", element: <Transactions /> },
            //   { path: "add-new-transaction", element: <AddNewTransaction /> },
            //   { path: "all-agents/:id", element: <UpdateAgentProfile /> },
            //   { path: "all-branches", element: <AllBranches /> },
            //   { path: "all-insurer", element: <AllInsurer /> },
            //   {path: "commission-management",element: <AccountManagement />,},
            //   { path: "account-management", element: <CommissionManagement /> },
            //   { path: "grievance-management", element: <GrievanceManagement /> },
            //   { path: "report-management", element: <ReportManagement /> },
            //   { path: "permission-management", element: <PermissionManagement /> },
            ],
          },
    ]);
}
export default Router;
