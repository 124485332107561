import {
  get_alluser_failure,
  get_alluser_request,
  get_alluser_success,
} from "./alluser.action";
// import axios from "axios";
import { api_route } from "../../Api-Config/config";
import authAxios from "../../Utils/axios";

export const getAllUsers = () => async (dispatch) => {
  try{

    dispatch(get_alluser_request());
    const response = await authAxios.get(
      `${api_route}/getallusers`
    );
    var resData = response.data.data;
    console.log(resData,"resdata on redux");
    // const allAgentsInfo = resData?.map((agent) => {
    //   const {
    //     _id,
    //     afterTrainingDetails,
    //     basicInfo,
    //     communicationDetails,
    //     userAccountInfo,
    //     joiningDate,
    //     acticeStatus,
    //     exam,
    //   } = agent;
  
    //   const { agentId, firstName, middleName, lastName } = userAccountInfo;
    //   const {
    //     communicationAddress,
    //     communicationState,
    //     communicationCity,
    //     communicationPincode,
    //     email,
    //     mobile,
    //   } = communicationDetails;
    //   const { gender, dob } = basicInfo;
    //   const { rmName, asmName, zmName, branch } = afterTrainingDetails;
    //   return {
    //     _id,
    //     agentId,
    //     fullName: `${firstName.toUpperCase()} ${middleName.toUpperCase()} ${lastName.toUpperCase()}`,
    //     joiningDate,
    //     branch,
    //     email,
    //     mobile,
    //     rmName,
    //     asmName,
    //     zmName,
    //     gender: gender.toUpperCase(),
    //     address: communicationAddress
    //       ? communicationAddress.toUpperCase()
    //       : "ADDRESS NOT FOUND",
    //     state: communicationState.toUpperCase(),
    //     city: communicationCity.toUpperCase(),
    //     pincode: communicationPincode,
    //     status: acticeStatus,
    //     dob: dob,
    //     exam,
    //   };
    // });
    resData = resData.map((item,i)=> {
      // let payload = {
      //   fullname : `${item.firstName} ${item.lastName}`,
      //   id:item._id,
      //   emailAuth:item.emailAuth,
      //   profilePicture:item.profilePicture

      // }
      return {...item,fullname : `${item.firstName} ${item.lastName}`,slNo:i+1};
    })
    resData = resData.sort((a,b)=> b.slNo - a.slNo)
    dispatch(get_alluser_success(resData));
    console.log(resData, "resdata in redux");
  }
  catch (err) {
    dispatch(get_alluser_failure());

  }
};
// };
