import React from 'react';
import styles from './MoreInfo.module.css';

// Material Components
import {
    Box,
    Stack,
    IconButton,
    Avatar,
} from '@mui/material';
import BackGroundImage from "../../assets/Images/CommanBacground.jpg"
// Icons
import CancelIcon from "@mui/icons-material/Cancel";

const MoreInfo = ({ handleClose, data }) => {
    console.log(data)
    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "100%", sm: "75%", md: "50%", lg: "40%" },
                    height: "450px",
                    overflowY: "auto",
                    scrollBehavior: "smooth",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 2,
                }}
            >
                <Stack direction="row" justifyContent="flex-end" mb={1}>

                    <IconButton color="error" onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </Stack>
                <Stack direction="row" p={1} justifyContent="center" sx={{
                    backgroundImage:`url(${BackGroundImage})`,
                    
                    backgroundPosition:"center",
                    backgroundRepeat:"no-repeat",
                    borderRadius:1,
                    backgroundSize:"auto"
                    
                    }}>
                    <Avatar
                        alt={data.profilePicture}
                        src={data.profilePicture}
                        sx={{ width: 100, height: 100 ,border:"2px solid green"}}
                    />
                </Stack>

                <Box mt={2}>
                    <table className={styles.table}>
                        <tr>
                            <th className={styles.th}>Name</th>
                            <td className={styles.td}>{data.fullname ? data.fullname : `${data.firstName} ${data.lastName}`}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Date of birth</th>
                            <td className={styles.td}>{data.dateOfBirth.split("T")[0]}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Time of birth</th>
                            <td className={styles.td}>{data.timeOfBirth}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Place of birth</th>
                            <td className={styles.td}>{data.placeOfBirth}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Blood Group</th>
                            <td className={styles.td}>{data.bloodGroup}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Email</th>
                            <td className={styles.td}>{data.email}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Phone Number</th>
                            <td className={styles.td}>{data.mobile}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Marital Status</th>
                            <td className={styles.td}>{data.maritalStatus}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Proffesion</th>
                            <td className={styles.td}>{data.profession}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Nature Of Job</th>
                            <td className={styles.td}>{data.natureOfJob}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Salary</th>
                            <td className={styles.td}>{data.salary}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Language</th>
                            <td className={styles.td}>{data.language}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Height</th>
                            <td className={styles.td}>{`${data.height.ft}' ${data.height.ft}"`}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Weight</th>
                            <td className={styles.td}>{data.weight}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Caste</th>
                            <td className={styles.td}>{data.caste}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Father's Gotra</th>
                            <td className={styles.td}>{data.gotraF}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Mother's Gotra</th>
                            <td className={styles.td}>{data.gotraM}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Grandmother's Gotra</th>
                            <td className={styles.td}>{data.gotraG}</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>Family Type</th>
                            <td className={styles.td}>{data.familyType}</td>
                        </tr>
                    </table>
                </Box>
            </Box>
        </>
    )
}

export default MoreInfo;