import React, { useState, useEffect } from 'react';

import {
    Box,
    Typography,
    Modal,
    FormGroup,
    FormControlLabel,
    Switch,
    Avatar,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';

// Material Table
import MaterialTable from "@material-table/core";

// Image
import defaultImage from "../../assets/Images/defaultAvatar.png";

// Icon
import InfoIcon from "@mui/icons-material/Info";

import MoreInfo from '../MoreInfo/MoreInfo';

import { useDispatch, useSelector } from "react-redux";
import { getAllActiveUsers } from '../../Redux/ActiveUsers/allActiveuserAPI';
import { api_route } from '../../Api-Config/config';
import authAxios from '../../Utils/axios';
import CommanMaterialTable from '../CommanUserTable/MaterialTable';

const ActiveUsers = () => {
    
    const { allActiveuser } = useSelector(state => 
        state.allActiveUser
    )
    console.log(allActiveuser)
    const dispatch = useDispatch();




    const color = '#e53267';

    const [open, setOpen] = useState(false);
    const [modData, setModData] = useState(null);
    const [request, setRequest] = useState(false);
    const [status, setStatus] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRequest = () => {
        setRequest((prev) => !prev);
    };
    const handleStatus = async ({ _id }) => {
        // setStatus((prev) => !prev);
        console.log(_id)
       
          await authAxios.put(`${api_route}/toggleemailauth`, {
           id:_id
          },).then(res=>{
            console.log(res)
            dispatch(getAllActiveUsers());
          }).catch (error=>{
          console.log(error.message)
        })
    
    
      };
    useEffect(() => {
        dispatch(getAllActiveUsers());
    }, [])
    const columns = [
        // { title: "ID", field: "id", width: "5rem" },
        {
            title: "Profile", width: "10rem",
            render: (rowData) => (
                // <img src={rowData.profile} style={{ width: 50, borderRadius: '50%' }} />
                <Avatar
                    sizes="large"
                    sx={{
                        border: `2px solid ${color}`,
                        width: 45,
                        height: 45,
                    }}
                    alt="match"
                    src={rowData.profilePicture?rowData.profilePicture:defaultImage}
                ></Avatar>
            )
        },
        { title: "Name", field: "fullname", width: "13rem", cellStyle: { overflowWrap: "break-word" },render:rowData=>(`${rowData.firstName} ${rowData.lastName}`) },
        {
            title: "Request", width: "10rem",
            render: (rowData) => (
                <Button
                    size="small"
                    variant="contained"
                    onClick={handleRequest}
                    sx={{
                        backgroundColor: request ? "grey" : color,
                        color: "white",
                        "&:hover": {
                            backgroundColor: request ? "grey" : color,
                            color: "white",
                        },
                    }}
                >
                    {request ? "Deny" : "Allow"}
                </Button>
            )
        },
        {
            title: "Status", width: "10rem",
            render: (rowData) => (
                <FormGroup>
                    <FormControlLabel control={
                        <Switch
                            checked={rowData.emailAuth}
                            onChange={() => handleStatus(rowData)}
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: color,
                                    '&:hover': {
                                        backgroundColor: 'rgba(229, 50, 103, 0.04)',
                                    },
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: color,
                                },
                            }}
                        />
                    }
                        label={status ? "Inactive" : "Active"} />
                </FormGroup>
            )
        },
        {
            title: "More Info", width: "10rem",
            render: (rowData) => (
                <Tooltip title="More Info" placement="left">
                    <IconButton
                        size="large"
                        onClick={() => {
                            setModData(rowData);
                            handleOpen();
                        }}
                    >
                        <InfoIcon sx={{ color: color }} />
                    </IconButton>
                </Tooltip>
            )
        },
        {
            title: "Payment Received", field: "paymentReceived", width: "12rem",
        },
        {
            title: "View Docs", field: "viewDocs", width: "10rem",
            render: (rowData) => (
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        backgroundColor: color,
                        color: "white",
                        "&:hover": {
                            backgroundColor: color,
                            color: "white",
                        },
                    }}
                >
                    View
                </Button>
            )
        }
    ];

    const activeUserData = [
        {
            id: 1,
            profile: defaultImage,
            name: "John Doe",
        },
    ];

    return (
        <>
        <CommanMaterialTable getUsers={getAllActiveUsers} usersData={allActiveuser} title="All Active  Users"/>
            {/* <Box mt={10} xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                    title={<Typography color={color} variant='h5'>All Active  Users</Typography>}
                    columns={columns}
                    data={allActiveuser}
                    options={{
                        actionsColumnIndex: -1,
                        addRowPosition: "first",
                        tableLayout: "fixed",
                        headerStyle: {
                            backgroundColor: color,
                            color: '#FFF',
                            fontWeight: "bold", fontSize: "1rem",
                        }
                    }}
                />
            </Box>

            {/* More Info Modal */}
            {/* <Modal open={open} onClose={handleClose}>
                <MoreInfo handleClose={handleClose} data={modData} />
            </Modal> */} 
        </>
    )
}

export default ActiveUsers;