import { GET_ALLUSER_FAILURE, GET_ALLUSER_REQUEST, GET_ALLUSER_SUCCESS } from "./alluser.actionType";

const initState = {
    alluser : [],
    allUserLoading : false,
    allUserError : false

}

export const alluser_reducer = (state=initState,{type,payload})=>{

    switch (type){
        case GET_ALLUSER_REQUEST :
            return {
                ...state,
                allUserLoading : true,
                allUserError : false
            }
        case GET_ALLUSER_SUCCESS : 
            return {
                ...state,
                allUserLoading : false,
                allUserError : false,
                alluser : payload
            }
        case GET_ALLUSER_FAILURE :
            return {
                ...state,
                allUserLoading : false,
                allUserError : true
            }
            default :
            return state
    }
}