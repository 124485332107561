import { Navigate } from "react-router-dom";
import Login from "../Components/Login/Login";
import useAuth from "../hooks/useAuth";

export default function GuestGuard({children}){
    const {user} = useAuth();

    if(user){
        return <Navigate to = '/dashboard' />
    }   
    return <>{children}</>
}