import React from 'react';

// Material Components
import {
  Box,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Stack,
  Avatar
} from '@mui/material';

// react-router-dom
import { useLocation, useNavigate } from 'react-router-dom';

// Image
// import defaultAvatar from '../../assets/defaultAvatar.png'

// Icons
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';

// styles
import { makeStyles } from '@mui/styles';
import useAuth from '../../hooks/useAuth';



export const drawerWidth = 250;

const useStyles = makeStyles({
  sideBarActive: {
    backgroundColor: '#f4f4f4',
    borderRight: '3px solid #e53267',
    color: '#e53267'
  },
  sideBarActiveIcon: {
    color: '#e53267'
  }
})

function Sidebar({ handleDrawerToggle, mobileOpen }) {


  const {user} = useAuth();

  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const sideBarMenu = [
    {
      label: "All Users",
      icon: <PeopleAltIcon />,
      link: "/admin/allUsers",
    },
    {
      label: "Active Users",
      icon: <GroupAddIcon />,
      link: "/admin/activeUsers",
    },
    {
      label: "Inactive Users",
      icon: <GroupRemoveIcon />,
      link: "/admin/inactiveUsers",
    },
    {
      label: "Dormant Users",
      icon: <GroupRemoveIcon />,
      link: "/admin/dormantUsers",
    }
  ];

  const drawer = (
    <div
      style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Box
        sx={{
          width: "100%",
          height: "3.7rem",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {/* <Avatar
          alt="user image"
          src={defaultAvatar}
          sx={{ width: 50, height: 50, backgroundColor: "#e53267" }}
        /> */}
        <Avatar sx={{ width: 40, height: 40, backgroundColor: "#e53267" }}>
          {user.firstName.split("")[0]}
        </Avatar>

        <Stack>
          <Typography
            variant="subtitle1"
            sx={{ color: "text.primary", fontWeight: "bold" }}
          >
          {user.firstName+user.middleName+" "+user.lastName}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <List sx={{ mt: 3 }}>
        {sideBarMenu.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={
              location.pathname === item.link ? classes.sideBarActive : null
            }
            onClick={() => {
              navigate(item.link);
              handleDrawerToggle(item.link);
            }}
          >
            <ListItemButton>
              <ListItemIcon
                className={
                  location.pathname === item.link
                    ? classes.sideBarActiveIcon
                    : null
                }
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>

    </Box>
  )

}





export default Sidebar;

