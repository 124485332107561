import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";

import Login from "../Components/Login/Login";

export default function AuthGuard({children}){
    const {user} = useAuth();
    const {pathname} = useLocation();

    const [requestLocation, setRequestLogin] = useState(null);
    console.log(user, "in auth guard")

    if(!user){
        if(pathname !== requestLocation){
            setRequestLogin(pathname);
        }
        return <Login />
    }

    if(requestLocation && pathname !== requestLocation){
        setRequestLogin(null);
        return <Navigate to = {requestLocation} />
    }

    return <>{children}</>
}